import { ChakraProvider, Box, Container, extendTheme, Button, Flex, Text} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import MyIntro from './MyIntro';
import Me from './Experience';
import Projects from './Projects';
import Projects2 from './Projects2';
import { Analytics } from '@vercel/analytics/react';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import IntroAnimation from './IntroAnimation';


const customTheme = extendTheme({
  fonts: {
    heading: "'Open Sans', sans-serif",
    body: "'Open Sans', sans-serif",
  },
  breakpoints: {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
  },
  styles: {
    global: {
      body: {
        backgroundSize: '400% 400%',
        minHeight: '100vh',
        backgroundColor: '#1b1816', //  navy peone: '#223a5e',
        color: '#937047' 
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        color: '#e7dac7',
        _hover: {
          bg: 'rgba(147, 112, 71, 0.5)', 
          color: '#e7dac7', 
          borderColor: '#937047', 
        },
      }
    },
    Text: {
      baseStyle: {
        color: '#937047'
      }
    },
    Heading: {
      baseStyle: {
        color: '#937047'
      }
    }
  }
});
const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

const PageTransition = ({ children }) => (
  <MotionBox
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0 }}
    transition={{
      duration: 0.5,
      ease: "easeInOut"
    }}
  >
    {children}
  </MotionBox>
);

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <PageTransition>
      <MotionFlex justify="center" align="center" height="75vh" direction="column">
        <MyIntro />
        <MotionFlex 
          justify="center" 
          align="center" 
          direction="row"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <Button 
            onClick={() => navigate('/about')}
            mr={4}
            variant="outline"
            borderColor="#937047"
            color="#e7dac7"
            _hover={{
              bg: 'rgba(147, 112, 71, 0.5)',
              color: '#e7dac7',
              borderColor: '#937047',
            }}
          >
            Experience
          </Button>
          <Button 
            onClick={() => navigate('/projects')}
            variant="outline"
            borderColor="#937047"
            color="#e7dac7"
            _hover={{
              bg: 'rgba(147, 112, 71, 0.5)',
              color: '#e7dac7',
              borderColor: '#937047',
            }}
          >
            Projects
          </Button>
        </MotionFlex>
      </MotionFlex>
    </PageTransition>
  );
};

const AboutPage = () => {
  const navigate = useNavigate();

  return (
    <PageTransition>
      <MotionFlex justify="center" align="center" height="100vh" direction="column">
        <Me />
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <Button 
            onClick={() => navigate('/')}
            m={2}
            variant="outline"
            borderColor="#937047"
            _hover={{
              bg: 'rgba(147, 112, 71, 0.5)',
              color: '#e7dac7',
              borderColor: '#937047',
            }}
          >
            Home
          </Button>
        </MotionBox>
      </MotionFlex>
    </PageTransition>
  );
};

const ProjectsPage = () => {
  const navigate = useNavigate();

  return (
    <PageTransition>
      <MotionFlex justify="center" align="center" height="100vh" direction="column">
        <Projects2 />
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <Button 
            onClick={() => navigate('/')}
            m={2}
            variant="outline"
            borderColor="#937047"
            _hover={{
              bg: 'rgba(147, 112, 71, 0.5)',
              color: '#e7dac7',
              borderColor: '#937047',
            }}
          >
            Home
          </Button>
        </MotionBox>
      </MotionFlex>
    </PageTransition>
  );
};

const AnimatedRoutes = () => {
  const location = useLocation();
  
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
      </Routes>
    </AnimatePresence>
  );
};

const App = () => {
  const [showIntro, setShowIntro] = useState(true);
  
  return (
    <ChakraProvider theme={customTheme}>
      <Router>
        {showIntro ? (
          <IntroAnimation onComplete={() => setShowIntro(false)} />
        ) : (
          <MotionBox
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ 
              duration: 1,
              ease: "easeInOut"
            }}
          >
            <Box p={{ base: "20px", sm: "30px", md: "50px" }}>
              <AnimatedRoutes />
            </Box>
          </MotionBox>
        )}
        <Analytics />
      </Router>
    </ChakraProvider>
  );
};

export default App;