import { Box, Button, Flex } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

const MotionFlex = motion(Flex);
const MotionButton = motion(Button);

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const navVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <MotionFlex
      justify="center"
      align="center"
      py={4}
      initial="hidden"
      animate="visible"
      variants={navVariants}
    >
      {location.pathname !== '/' && (
        <MotionButton
          onClick={() => navigate('/')}
          variant="outline"
          borderColor="#937047"
          color="#e7dac7"
          _hover={{
            bg: 'rgba(147, 112, 71, 0.5)',
            color: '#e7dac7',
            borderColor: '#937047',
          }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Home
        </MotionButton>
      )}
      {location.pathname === '/' && (
        <>
          <MotionButton
            onClick={() => navigate('/about')}
            mr={4}
            variant="outline"
            borderColor="#937047"
            color="#e7dac7"
            _hover={{
              bg: 'rgba(147, 112, 71, 0.5)',
              color: '#e7dac7',
              borderColor: '#937047',
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Experience
          </MotionButton>
          <MotionButton
            onClick={() => navigate('/projects')}
            variant="outline"
            borderColor="#937047"
            color="#e7dac7"
            _hover={{
              bg: 'rgba(147, 112, 71, 0.5)',
              color: '#e7dac7',
              borderColor: '#937047',
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Projects
          </MotionButton>
        </>
      )}
    </MotionFlex>
  );
}