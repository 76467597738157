import { ReactElement } from 'react';
import { Image, Link, useBreakpointValue, Box, SimpleGrid, Icon, Text, HStack, Stack, Flex, Heading, Spacer, Button } from '@chakra-ui/react';
import { FcAssistant, FcDonate, FcInTransit } from 'react-icons/fc';
import Highlighter from "react-highlight-words";
import Emoji from './Emoji';
import { useColorMode, useColorModeValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import AnimatedEmojis from './AnimatedEmojis';

// Create motion components
const MotionBox = motion(Box);
const MotionStack = motion(Stack);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);
const MotionHStack = motion(HStack);
const MotionImage = motion(Image);

export default function MyIntro() {
  const { toggleColorMode } = useColorMode();
  const color = 'white';
  
  // Your image imports
  const linked_in_white = require('./images/linkedin.svg').default;
  const linked_in_black = require('./images/linkedin_black.svg').default;
  const linkedInImage = useColorModeValue(linked_in_white, linked_in_white);
  
  const email_white = require('./images/email.svg').default;
  const email_black = require('./images/email_black.svg').default;
  const email = useColorModeValue(email_white, email_white);

  const github_white = require('./images/github_white.svg').default;
  const github_black = require('./images/github.svg').default;
  const github = github_white;

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.15
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <MotionBox
      id="MyIntro"
      margin={{ base: 5, md: 6}}
      paddingTop={{ sm: 40, md: 50, lg: 100 }}
      pr={{ base: 6, md: 100 }}
      pl={{ base: 6, md: 100 }}
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <Flex justify="center" align="center" direction="column">
        <MotionStack
          spacing={2}
          w={'full'}
          maxW={'lg'}
          textAlign="center"
          variants={containerVariants}
        >
          <MotionBox variants={itemVariants}>
            <AnimatedEmojis />
          </MotionBox>

          <MotionHeading size='3xl' variants={itemVariants}>
            <Emoji symbol="👨🏻‍💻" />
          </MotionHeading>

          <MotionHeading 
            fontSize={{ base: '3xl', md: '3.5xl', lg: '4xl' }}
            variants={itemVariants}
          >
            <MotionText 
              fontSize={'111%'} 
              as={'span'} 
              position={'relative'}
              variants={itemVariants}
            >
              Jacey Buchner
            </MotionText>
          </MotionHeading>

          <MotionText 
            color={"#e7dac7"} 
            fontSize={{ base: 'medium', lg: 'xl' }}
            variants={itemVariants}
          >
            Recent UCSB Comp Sci Alum <Emoji symbol="🌴" />
          </MotionText>

          <MotionHStack 
            spacing={2} 
            justify="center"
            variants={itemVariants}
          >
            <Link isExternal href="https://www.linkedin.com/in/jaceybuchner/">
              <MotionImage
                height={'4vh'}
                mb={'2'}
                maxW={"90%"}
                alt={'LinkedIn'}
                objectFit={'contain'}
                src={linkedInImage}
                variants={imageVariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              />
            </Link>

            <Link isExternal style={{ textDecoration: 'none' }} href="mailto:jaceybuchner@gmail.com">
              <MotionImage
                height={'4vh'}
                mb={'2'}
                maxW={"90%"}
                alt={'Email'}
                objectFit={'contain'}
                src={email}
                variants={imageVariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              />
            </Link>
          </MotionHStack>
        </MotionStack>
      </Flex>
    </MotionBox>
  );
}