import { ReactElement } from 'react';
import {
  Image, Code, Link, Box, SimpleGrid, Icon, Text, Stack, Flex, Heading
} from '@chakra-ui/react';
import { FcAssistant, FcDonate, FcInTransit, FcOk } from 'react-icons/fc';
import { useColorMode } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';

// Create motion components
const MotionBox = motion(Box);
const MotionStack = motion(Stack);
const MotionHeading = motion(Heading);
const MotionSimpleGrid = motion(SimpleGrid);
const MotionText = motion(Text);
const MotionCode = motion(Code);

const Feature = ({ url, title, text, lnk, l1, l2, l3 }) => {
  const color = "#e7dac7";

  const stackItemVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3
      }
    }
  };
  
  return (
    <Link 
  href={lnk}
  isExternal
  _hover={{ textDecoration: 'none' }}
  width="100%"
  display="block"
>
    <MotionStack 
      spacing={2}
      p={4} 
      borderWidth={1} 
      borderRadius="md" 
      borderColor="#937047"
      bg="rgba(0,0,0,0.1)"
      height="100%"
      minH="130px"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={{
        hidden: { opacity: 0, y: 20 },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.5,
            ease: "easeOut",
            staggerChildren: 0.1
          }
        }
      }}
      whileHover={{
        scale: 1.02,
        transition: { duration: 0.2 }
      }}
    >
      <Box > 
        <Flex justifyContent="space-between" alignItems="center" mb={2}>
          <MotionText
            color={color}
            fontWeight={600}
            variants={{
              hidden: { opacity: 0, y: 10 },
              visible: { opacity: 1, y: 0 }
            }}
          >
            <Link isExternal href={lnk}>
              {title} <ExternalLinkIcon mx='2px' />
            </Link>
          </MotionText>
        </Flex>
        
        <MotionText
          color={color}
          fontSize="sm"
          mb={2}
          variants={{
            hidden: { opacity: 0, y: 10 },
            visible: { opacity: 1, y: 0 }
          }}
        >
          {text}
        </MotionText>
      </Box>
      
      <Flex alignItems="center" gap={2}>
        <MotionText
          color="#e7dac7"
          fontSize={12}
          fontWeight="bold"
          variants={stackItemVariants}
        >
          STACK:
        </MotionText>
        <MotionCode
          bg="rgba(211, 211, 211, 0.4)"
          color="#e7dac7"
          fontSize="xs"
          variants={stackItemVariants}
        >
          {l1}
        </MotionCode>
        <MotionCode
          bg="rgba(211, 211, 211, 0.4)"
          color="#e7dac7"
          fontSize="xs"
          variants={stackItemVariants}
        >
          {l2}
        </MotionCode>
        <MotionCode
          bg="rgba(211, 211, 211, 0.4)"
          color="#e7dac7"
          fontSize="xs"
          variants={stackItemVariants}
        >
          {l3}
        </MotionCode>
      </Flex>
    </MotionStack>
    </Link>
  );
};

export default function Projects() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.2
      }
    }
  };

  return (
    <MotionBox
      id="Projects"
      px={{ base: 4, md: 20 }}
      py={4}
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <MotionHeading
        fontSize={{ base: 'xl', md: '2xl' }}
        textAlign="center"
        mb={4}
        color={"#e7dac7"}
        variants={{
          hidden: { opacity: 0, y: -20 },
          visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: "easeOut" }
          }
        }}
      >
        Projects
      </MotionHeading>
      
      <MotionSimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={3}
        justifyItems="stretch"
        alignItems="stretch"
        variants={containerVariants}
      >
        <Feature
          title={'cryptaid'}
          text={'Platform for secure, anonymous donations to organizations using Ethereum'}
          lnk={"https://cryptaid-theta.vercel.app/"}
          l1={"Solidity"}
          l2={"React"}
          l3={"Moralis SDK"}
          url={require('./images/cryptaid.png')}
        />
        
        <Feature
          title={'30Seconds'}
          text={'Website for behavioral interview practice, providing feedback on self-recorded interviews'}
          lnk={"https://seconds-ef259.web.app/"}
          l1={"HTML/CSS"}
          l2={"React"}
          l3={"AssemblyAI API"}
          url={require('./images/30s.png')}
        />
        
        <Feature
          title={'Fiesta'}
          text={'Social platform for discovering, rating, and reviewing parties on a map'}
          lnk={"https://fiesta-5caa1.web.app/"}
          l1={"Firebase"}
          l2={"React"}
          l3={"Google Maps API"}
          url={require('./images/fiesta.png')}
        />
        
        <Feature
          title={'InstaAid'}
          text={'Health app to connect users with friends or family during an epipen or inhaler emergency'}
          lnk={"https://devpost.com/software/instaaid-3rmdzf"}
          l1={"Swift/Xcode"}
          l2={"Node.js"}
          l3={"Sketch"}
          url={require('./images/instaaid.png')}
        />
      </MotionSimpleGrid>
    </MotionBox>
  );
}