import { Link, Box, Heading, Text, Flex, UnorderedList, ListItem } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);
const MotionHeading = motion(Heading);
const MotionListItem = motion(ListItem);

export default function Me() {
  const color = "#e7dac7";

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1
      }
    }
  };

  return (
    <MotionBox
      id="About"
      px={{ base: 4, md: 20 }}
      py={4}
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <MotionHeading
        fontSize={{ base: 'xl', md: '2xl' }}
        textAlign="center"
        mb={4}
        color={color}
        variants={itemVariants}
      >
        Experience:
      </MotionHeading>

      <MotionBox
        borderWidth={1}
        borderRadius="md"
        borderColor="#937047"
        bg="rgba(0,0,0,0.1)"
        p={4}
        maxW="3xl"
        mx="auto"
        variants={itemVariants}
      >
        <UnorderedList
          spacing={3}
          listStyleType="none"
          color={color}
          pl={0}
          fontSize="lg"
          m={0}
        >
          <MotionListItem mb={2} variants={itemVariants}>
            Previous SWE Intern at{' '}
            <Text as="span" color="#937047" fontWeight="bold">
              Google
            </Text>
          </MotionListItem>

          <MotionListItem mb={2} variants={itemVariants}>
            Undergrad Researcher at{' '}
            <Link
              href="https://seclab.cs.ucsb.edu/"
              isExternal
              color="#937047"
              _hover={{ color: color }}
            >
              SecLab@UCSB
            </Link>
          </MotionListItem>

          <MotionListItem mb={2} variants={itemVariants}>
            Peer Mentor in the{' '}
            <Link
              href="https://inspire.engineering.ucsb.edu/meet-team"
              isExternal
              color="#937047"
              _hover={{ color: color }}
            >
              Inspire Program
            </Link>
          </MotionListItem>

          <MotionListItem mb={2} variants={itemVariants}>
            Vice President of{' '}
            <Link
              href="https://x.com/blockchainucsb?lang=en"
              isExternal
              color="#937047"
              _hover={{ color: color }}
            >
              Blockchain@SB
            </Link>
          </MotionListItem>

          <MotionListItem variants={itemVariants}>
            PM Intern at{' '}
            <Link
              href="https://healthy.kaiserpermanente.org/northern-california/front-door"
              isExternal
              color="#937047"
              _hover={{ color: color }}
            >
              Kaiser Permanente
            </Link>
          </MotionListItem>
        </UnorderedList>
      </MotionBox>
    </MotionBox>
  );
}